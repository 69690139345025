<template>
    <div class="shop-category-content">
        <div class="pw-score-main">
            <el-table :data="data" style="width: 100%" height="100%" header-cell-class-name="pw-table-header">
                <el-table-column prop="competition_name" label="竞赛名称" width="200" align="center"></el-table-column>
                <el-table-column prop="username" label="工位号" width="100" align="center"></el-table-column>
                <el-table-column prop="" label="答案" align="center">
                    <template slot-scope="scope">
                        <div class="pw-store-banner">
                            <span v-if="scope.row.answer">{{formatCate(scope.row.answer)}}</span>
                            <span v-else>该学生未提交答案</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="" :label="`评分（分值最高为${totalScore}分）`" width="200" align="center">
                    <template slot-scope="scope">
                        <div class="pf-act-content">
                            <!--<template v-if="isScoreList[scope.row.s_id]">
                            </template>-->
                            <el-input-number v-model="scope.row.score" controls-position="right" class="pw-input"  :min="0" :max="totalScore"></el-input-number>
                            <el-button v-if="scope.row.is_pw" size="small"  @click="confirmScore(scope.row)">确定</el-button>
                            <el-button v-else type="primary" size="small" class="pw-confirm-btn" @click="confirmScore(scope.row)">确定</el-button>
                            <!--<template v-else>
                                <span>{{scope.row.score}}分</span>
                            </template>-->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <router-link :to="{ path: '/teacher/student/store', query: {cid: $route.query.id, sid: scope.row.s_id} }" tag="a" target="_blank" ><span>查看详情</span></router-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pw-page-pagination">
            <el-pagination
                background
                layout="prev, pager, next"
                :current-page.sync="currentPage"
                :page-size.sync="limit"
                @current-change="changePage"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import _ from "underscore";
import {btnFormat} from "@/utils/utils";

export default {
    name: "ShopCategoryScore",
    data() {
        return {
            currentPage: 1,
            total: 10,
            data: [],
            totalScore: 0,
            isScoreList: [],
            limit: 10,
        }
    },
    methods: {
        getStuList() {
            let param = {
                page: this.currentPage,
                limit: this.limit,
                type: 3,
                competition_id: this.$route.query.id
            }
            this.$http.axiosGetBy(this.$api.answerList, param,
                (res) => {
                    if (res.code === 200) {
                        // this.data = res.data.Answer;
                        // console.log("res", res.data.Answer)

                        this.data = btnFormat(res.data.Answer)
                        this.totalScore = res.data.model_score;
                        this.total = res.data.stu_total;
                    } else {
                        this.data = [];
                    }
                },
                (error) => {
                    console.log(error);
                    this.$common.axiosErrorMsg(error);
                });
        },
        changePage(val) {
            this.currentPage = val;
            this.getStuList();
        },
        confirmScore(value) {
            let data = {
                competition_id: this.$route.query.id,
                result_type: 3,
                user_id: value.s_id,
                score: value.score
            }
            this.$http.axiosPost(this.$api.pwScore, data,
                (res) => {
                    if (res.code === 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getStuList();
          }else{
               this.$message({
              type: "warning",
              message: res.msg,
            });
          }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
        },
        formatCate(arr) {
            if (arr.length > 0) {
                let tmp = arr.length > 5 ? arr.slice(0, 4) : arr;
                return arr.join(' | ');
            } else {
                return '暂无分类';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .shop-category-content {
        padding: 30px 30px 0 30px;
        height: calc(100% - 30px);
        /deep/ .pw-table-header {
            background-color: #F3F4FA;
            color: #14141C;
            font-size: 14px;
        }
        .pw-store-banner {
            height: 80px;
            width: 100%;
            display: flex;
            padding: 0 20px;
            justify-content: center;
            align-items: center;
            img {
                width: 320px;
                height: 80px;
            }
        }
        .pf-act-content {
            display: flex;
            justify-content: center;
            align-items: center;
            .pw-input {
                width: 100px;
                margin-right: 8px;
            }
            .pw-confirm-btn {
                background-color: #1222D8;
                border-color: #1222D8;
            }
        }
    }

</style>
