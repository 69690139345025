<template>
  <div class="answer-score-container">
    <div class="score-container-main">
      <el-table
        :data="scoreList"
        style="width: 100%"
        height="94.5%"
        header-cell-class-name="pw-table-header"
        :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
        :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
      >
        <el-table-column
          prop="paper_name"
          label="竞赛名称"
          width="170"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="student_name"
          label="工位号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="question_title"
          label="问答题"
          width="260"
          align="center"
        >
        <template slot-scope="scope">
          <span v-html="scope.row.question_title"></span>
        </template>
        </el-table-column>
        <el-table-column
          prop="analysis"
          label="解析"
          width="390"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.analysis==''">无解析</span>
            <span v-else-if="scope.row.analysis==null">无解析</span>
            <span v-else v-html="scope.row.analysis"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stu_answer"
          label="学生答案"
          width="260"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.stu_answer == '[]'">未作答</span>
            <span v-else>{{
              JSON.parse(scope.row.stu_answer)[0] == ""
                ? "未作答"
                : JSON.parse(scope.row.stu_answer)[0]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="options" label="操作" width="379" align="center">
          <template slot-scope="scope">
            <div class="operation-flex">
              <div class="score-main" style="margin-right: 25px">
                <el-input-number
                  controls-position="right"
                  v-model="scope.row.score"
                  :min="0"
                  :max="parseInt(scope.row.highest_score)"
                  label="0"
                  placeholder="0"
                  style="width: 120px"
                  :disabled="scope.row.flag"
                ></el-input-number>
                <div
                  style="
                    font-size: 14px;
                    color: rgba(153, 153, 153, 1);
                    margin-top: 5px;
                  "
                >
                  （最高分{{ scope.row.highest_score }}分）
                </div>
              </div>

              <el-button v-if="scope.row.is_check === 0"
                type="primary"
                style="height: 40px; width: 85px"
                @click="submit(scope.row)"
                >确定</el-button
              >
                <el-button v-else
                    style="height: 40px; width: 85px"
                    @click="submit(scope.row)"
                >确定</el-button
                >
              <!-- <el-button
                :disabled="scope.row.check"
                style="height: 40px; width: 85px"
                @click="updateScore(scope.row)"
                >修改</el-button
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pages-center"
        :current-page="listPages.currentPageNum"
        :page-size="listPages.eachPageNum"
        layout="prev, pager, next"
        :total="listPages.total"
        background
        @current-change="pageCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { showScoreList, editScore } from "@/utils/apis";
import {btnFormat} from "@/utils/utils";
export default {
  props: ["competitionId"],
  data() {
    return {
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      scoreList: [],
    };
  },
  mounted() {
    this.showScoreListData();
  },
  methods: {
    // 评分列表
    showScoreListData() {
      let params = {
        competition_id: this.$route.query.id,
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      };
      showScoreList(params).then((res) => {
        if (res.code === 200) {
          this.scoreList = res.data.list;
          this.listPages.total = res.data.total;
          this.scoreList.map((item) => {
            item.flag = false;
            item.check = true;
            return item;
          });
        } else {
          this.scoreList = [];
        }
      });
    },
    updateFlag(row) {
      row.flag = !row.flag;
      this.$forceUpdate();
    },
    submit(row) {
      if (row.flag == true) {
        this.$message({
          message: "评分已提交，如需修改评分请点击右侧修改按钮修改评分",
          type: "warning",
        });
      } else {
        this.$confirm("是否提交评分, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // row.flag = true;
            // this.$forceUpdate();
            let params = {
              id: row.id,
              score: row.score,
            };
            editScore(params).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "评分成功",
                  type: "success",
                });
                // row.check = false;
                this.$forceUpdate();
              } else {
                console.log(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消修改提交",
            });
          });
      }
    },
    // updateScore(row) {
    //   this.$confirm("是否确定修改评分结果, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.updateFlag(row);
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "取消修改评分",
    //       });
    //     });
    // },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showScoreListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-score-container {
  padding: 30px 30px 0 30px;
  display: flex;
  overflow-x: auto;
  height: calc(100% - 30px);
  ::v-deep .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .score-container-main {
    ::v-deep .el-button--primary {
      background-color: rgba(17, 34, 216, 1);
      border-color: rgba(17, 34, 216, 1);
    }
    .pages-center {
      margin-top: 5px;
      text-align: center;
    }
    .operation-flex {
      display: flex;
    }
    .el-table {
      flex: 1;
    }
    .pw-page-pagination {
    }
  }
}
</style>
