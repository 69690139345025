var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-img-content"},[_c('div',{staticClass:"pw-score-main"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"height":"100%","header-cell-class-name":"pw-table-header"}},[_c('el-table-column',{attrs:{"prop":"competition_name","label":"竞赛名称","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"username","label":"工位号","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"prop":"","label":"答案(商品只展示前5件商品)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"pw-store-banner"},[(scope.row.answer)?_vm._l((scope.row.answer),function(item,index){return _c('div',{staticClass:"goods-list"},[(index < 5)?_c('div',{staticClass:"goods-item"},[_c('router-link',{attrs:{"to":{
                      path: '/teacher/student/goods',
                      query: { id: item.goods_id },
                    },"tag":"a","target":"_blank"}},[_c('img',{attrs:{"src":item.url,"alt":"查看商品详情"}})])],1):_vm._e()])}):[_c('span',[_vm._v("该学生未提交答案")])]],2)]}}])}),_c('el-table-column',{attrs:{"prop":"","label":`评分（分值最高为${
          _vm.totalScore == null ? '0' : _vm.totalScore
        }分）`,"width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"pf-act-content"},[_c('el-input-number',{staticClass:"pw-input",attrs:{"controls-position":"right","min":0,"max":_vm.totalScore},model:{value:(scope.row.score),callback:function ($$v) {_vm.$set(scope.row, "score", $$v)},expression:"scope.row.score"}}),(scope.row.is_pw)?_c('el-button',{attrs:{"size":"small","plain":""},on:{"click":function($event){return _vm.confirmScore(scope.row)}}},[_vm._v("确定")]):_c('el-button',{staticClass:"pw-confirm-btn",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.confirmScore(scope.row)}}},[_vm._v("确定")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              path: '/teacher/student/store',
              query: { cid: _vm.$route.query.id, sid: scope.row.s_id },
            },"tag":"a","target":"_blank"}},[_c('span',[_vm._v("查看详情")])])]}}])})],1)],1),_c('div',{staticClass:"pw-page-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.currentPage,"page-size":_vm.limit,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:pageSize":function($event){_vm.limit=$event},"update:page-size":function($event){_vm.limit=$event},"current-change":_vm.changePage}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }